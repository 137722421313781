<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Ward master</h5>
                    </div>
                    <div class="headerButtons">
                        <img *ngIf="!showCreateDetailFlag" class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/new_button.svg"
                        (click)="createMaster()" style="margin-left: 9px ;"/>    
                        <img *ngIf="showCreateDetailFlag" class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/Back_button.svg"
                        (click)="goBack()" style="margin-left: 9px ;"/>    
                        <img *ngIf="showCreateDetailFlag && !updateFlag" src="../../../assets/ui_icons/buttons/save_button.svg"
                        class="saveimgbtn_inpatinfo" (click)="addMaster('add')" />
                        <img *ngIf="showCreateDetailFlag && updateFlag" src="../../../assets/ui_icons/buttons/update_button.svg"
                        class="saveimgbtn_inpatinfo" (click)="addMaster('update')" />
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row" *ngIf="!showCreateDetailFlag">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <p class="nodata" *ngIf="wardList.length == 0">No ward(s) found</p>
                        <table *ngIf="wardList.length" mdbTable datatable [dtOptions]="dtOptions"
                          class="mt-4 mb-4 table table-nowrap table-sm dataTable billlisttable">
                          <thead>
                            <tr>
                              <th>Ward name</th>
                              <th>Number of beds</th>
                              <th>Floor</th>
                              <th>Active/Inactive</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let ward of wardList; let i = index">
                              <td (click)="getWardList(ward)" style="font-size: 14px; text-align: left;">{{ ward.ward_name }}</td>
                              <td (click)="getWardList(ward)" style="font-size: 14px; text-align: center;">{{ ward.beds }}</td>
                              <td (click)="getWardList(ward)" style="font-size: 14px; text-align: center;">{{ ward.floor }}</td>
                              <td style="text-align: center;">
                                <label class="switch">
                                    <input type="checkbox" [(ngModel)]="ward.status" (change)="wardStatusChange(ward)">
                                    <span class="slider round"></span>
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <br><br><br>
                      </div>
                </div>
                <div class="row" *ngIf="showCreateDetailFlag">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom"> <!--col-sm-6 col-md-3 col-lg-3 col-xl-3-->
                        <mat-label class="matlabel ">Ward name
                            <input class="ipcss" [(ngModel)]="wardName" matInput>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom">
                        <mat-label class="matlabel ">Floor
                            <input class="ipcss" [(ngModel)]="floor" matInput>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" *ngIf="showCreateDetailFlag && !updateFlag">
                        <mat-label class="matlabel ">Bed charge
                            <input class="ipcss" [(ngModel)]="bedCharges" style="text-align:right;" (keyup)="createBedCharges()" required  onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput>                   
                            </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom">
                        <mat-label class="matlabel ">Nursing charge
                            <input class="ipcss" [(ngModel)]="nursingCharges" style="text-align:right;" required  onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput> <!--(keyup)="createNursingCharges()"-->           
                            </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom">
                        <mat-label class="matlabel">Bed count
                            <input class="ipcss" [(ngModel)]="bed_count" style="text-align:right;" (keyup)="createBed()" required matInput>                   
                            </mat-label>
                    </div>
                    <div class="col-9" style="width: 71%;">
                        <img class="pluseIcon" src="../../../assets/ui_icons/Add_icon.svg"
                        (click)="createTable()" style="margin: 8px; margin-top:18px;float: right;"/>                  
                    </div>
                    <div class="col-12">
                        <div style="width: 75%;width: 100%;display: flex;flex-direction: row;align-items: center;justify-content: center;">
                            <div class="table-responsive">
                                <table class="table table-hover table-dynamic" id="bedTable">
                                    <thead>
                                        <tr>
                                            <th>Bed no</th>
                                            <th>Bed type</th>
                                            <th>Bed charge</th>
                                            <th>Active/Inactive</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let bed of bedTableCount">
                                            <td>
                                                <input class="ipcss" [(ngModel)]="bed.bed_no" maxlength="15" matInput>
                                            </td>
                                            <td>
                                                <select disableOptionCentering class="ipcss inpat_width"
                                                [(ngModel)]="bed.bed_type">
                                                <option *ngFor="let bed of bedList" value="{{bed.bedtypes_id}}">
                                                    {{bed.bed_type}}</option>
                                            </select>    
                                            </td>
                                            <td>
                                                <input style="text-align: right;" class="ipcss" (click)="bed.rate = ''" (change)="bedRate()" [(ngModel)]="bed.rate"  onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput>
                                            </td>
                                            <td style="text-align: center;">
                                                <label class="switch">
                                                    <input type="checkbox" [(ngModel)]="bed.status" (change)="bedStatusChange(bed)">
                                                    <span class="slider round"></span>
                                                </label>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card> <br><br><br>
    </div>
</div>